import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { forwardRef } from "@chakra-ui/react";
import { useField } from "formik";
import React, { useMemo } from "react";

import * as S from "./styled";
import s from "./styles.module.less";

interface IChildProps {
  saveChilds: (count: number) => void;
  childs: string | number;
}

interface IAdultProps {
  saveAdult: (count: number) => void;
  adult: string | number;
}
const RenderAdult = ({ saveAdult, adult }: IAdultProps) => (
  <div className={s.childBlock}>
    <div>
      <div className={s.childBlockTitle}>Взрослый</div>
    </div>

    <div className={s.numberButton}>
      <div className={s.controlPanelPassOff}>
        <button onClick={() => saveAdult(-1)}>
          <i className={`${s.circleIcon} fas fa-minus`} />
        </button>
      </div>
      {adult}
      <div className={s.controlPanelPassOn}>
        <button onClick={() => saveAdult(+1)}>
          <i className={`${s.circleIcon} fas fa-plus`} />
        </button>
      </div>
    </div>
  </div>
);

const RenderChilds = ({ saveChilds, childs }: IChildProps) => (
  <div className={s.childBlock}>
    <div>
      <div className={s.childBlockTitle}>Детский</div>
      <div className={s.childBlockSubTitle}>до&nbsp;13 лет</div>
    </div>

    <div className={s.numberButton}>
      <div className={s.controlPanelPassOff}>
        <button onClick={() => saveChilds(-1)}>
          <i className={`${s.circleIcon} fas fa-minus`} />
        </button>
      </div>
      {childs}
      <div className={s.controlPanelPassOn}>
        <button onClick={() => saveChilds(+1)}>
          <i className={`${s.circleIcon} fas fa-plus`} />
        </button>
      </div>
    </div>
  </div>
);
const Input = forwardRef((props, ref) => <div {...props} ref={ref} className={s.passangersBlock}></div>);

const Passengers = () => {
  const [adult, , helperAdult] = useField({ name: "adult", type: "number", value: 0 });
  const [childs, , helperChilds] = useField({ name: "childs", type: "number", value: 0 });

  const saveAdult = (increment = 0) => {
    const prevCount = +adult.value;
    const count = prevCount + increment;

    if (count > 0) {
      helperAdult.setValue(count);
    }
  };

  const saveChilds = (increment = 0) => {
    const prevCount = +childs.value;
    const count = prevCount + increment;

    if (count >= 0) {
      helperChilds.setValue(count);
    }
  };

  const count = useMemo(() => Number(adult.value) + Number(childs.value), [adult.value, childs.value]);
  const label = useMemo(() => {
    let passangersLabel = "";
    if (count === 1) {
      passangersLabel = " взрослый";
    }

    if (count > 1 && count < 5) {
      passangersLabel = " человека";
    }

    if (count >= 5) {
      passangersLabel = " человек";
    }
    return passangersLabel;
  }, [count]);

  return (
    <S.LabelWrapper maxWidth={"150px"}>
      <S.Label>Кол. пассажиров</S.Label>
      <Menu closeOnSelect={false}>
        <MenuButton as={Input}>
          {count} {label}
        </MenuButton>
        <MenuList onClick={(event) => event.preventDefault()}>
          <MenuItem as="div">
            <RenderAdult adult={adult.value} saveAdult={saveAdult} />
          </MenuItem>
          <MenuItem as="div">
            <RenderChilds childs={childs.value} saveChilds={saveChilds} />
          </MenuItem>
        </MenuList>
      </Menu>
    </S.LabelWrapper>
  );
};

export default Passengers;
