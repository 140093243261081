import styled from "@emotion/styled";

import { newColors as colors, styles } from "@/styles/variables";

export const SearchFormWrapper = styled.div<{ isRoutes?: boolean }>`
  background: ${(props) => (props.isRoutes ? "#ffffff" : colors.whiteTransparent)};
  padding: 20px 40px;
  color: ${colors.gray};
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  ${(props) => props.isRoutes && "border-radius: 8px;"};
  ${(props) => props.isRoutes && `box-shadow: ${styles.shadow};`};

  @media (max-width: 600px) {
    border-top-left-radius: 8px;
  }
  @media (max-width: 450px) {
    padding: 20px 25px;
  }
`;
export const ButtonSwapCities = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SearchRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 3px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SwapIcon = styled.i`
  font-size: 20px;
  color: ${colors.gray};
`;
export const Label = styled.label`
  font-size: 12px;
  color: ${colors.gray};
  display: block;
  margin-bottom: 3px;
`;
export const LabelWrapper = styled.div<{ maxWidth?: string }>`
  width: 100%;
  max-width: ${(props) => props.maxWidth || "285px"};

  @media (max-width: 767px) {
    max-width: 470px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: 2px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const FlexLink = styled.a<{ active?: boolean }>`
  gap: 5px;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
  background-color: ${colors.whiteTransparent};

  & span {
    color: ${(props) => (props.active ? colors.mainBlue : colors.gray)};
  }
  & :first-child {
    border-top-left-radius: 8px;
  }

  & :last-child {
    border-top-right-radius: 8px;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const SearchCityWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: flex-end;
  @media (max-width: 500px) {
    align-items: center;
    flex-direction: column;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 280px;
  align-items: flex-end;

  @media (max-width: 767px) {
    max-width: initial;
  }
`;
