import { Field } from "formik";
import React from "react";

import CustomRadio from "./CustomRadio";

const RadioField = ({ name, ...props }: any) => {
  return <Field name={name} component={CustomRadio} {...props} />;
};

export default RadioField;
