import React, { memo } from "react";
import { RadioGroup } from "@chakra-ui/radio";
import { Stack } from "@chakra-ui/react";
import { type FieldProps } from "formik";

import * as S from "./styled";

interface Option {
  label: string;
  value: string;
  disabled: boolean;
}
interface RadioProps extends FieldProps {
  options: Option[];
}

const CustomRadio = ({ field, form, ...props }: RadioProps) => {
  return (
    <RadioGroup onChange={(value) => form.setFieldValue(field.name, value)} value={field.value} {...props}>
      <Stack direction="row">
        {props.options.map((option, index) => (
          <S.Radio isDisabled={option.disabled} size="lg" value={option.value} key={index}>
            {option.label}
          </S.Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default memo(CustomRadio);
