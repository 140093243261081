import React, { memo, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import moment from "moment";
import { NinetyRingWithBg } from "react-svg-spinners";
import { Form, Formik, useField } from "formik";
import ym from "@appigram/react-yandex-metrika";
import { useMediaQuery } from "@chakra-ui/react";

import { selectCities } from "@/store/selectors/globalData";
import { useAppSelector } from "@/store/store";
import Button from "@/components/Buttons";
import car from "public/images/icon-car.svg";
import bus from "public/images/bus.svg";
import relax from "public/images/relax.svg";
import hotel from "public/images/hotel-icon.svg";
import { debounceAsync, IS_DEVELOP, parseSearchString } from "@/utils";
import { DateField, SelectField } from "@/components/FormComponents";
import RadioField from "@/components/FormComponents/RadioField";
import httpClient from "@/utils/http";
import { City } from "@/store/slices/searchForm";

import * as S from "./styled";
import Passangers from "./Passangers";

interface InitialValues {
  type: string;
  from: City | null;
  to: City | null;
  date: string | Date | null;
  dateBack: string | null;
  adult: number;
  childs: number;
}

const RenderSwapButton = () => {
  const [cityFrom, , helperCityFrom] = useField("from");
  const [cityTo, , helperCityTo] = useField("to");

  const swapCities = () => {
    const data = {
      from: cityFrom.value,
      to: cityTo.value
    };
    helperCityTo.setValue(data.from);
    helperCityFrom.setValue(data.to);
  };

  return (
    <S.ButtonSwapCities type="button" onClick={swapCities}>
      <S.SwapIcon className="fas fa-exchange-alt" />
    </S.ButtonSwapCities>
  );
};

const RenderDate = () => {
  const [field] = useField("type");
  const matches = useMediaQuery("(min-width: 768px)");
  if (field.value === "2") {
    return (
      <S.DateWrapper>
        <DateField
          styleFormWrapper={{ maxWidth: "initial" }}
          name="date"
          minDate={moment().toDate()}
          label="Дата поездки"
        />
        <DateField styleFormWrapper={{ maxWidth: "initial" }} name="dateBack" minDate={moment().toDate()} label="" />
      </S.DateWrapper>
    );
  }

  return (
    <DateField
      styleFormWrapper={{ maxWidth: matches ? "225px" : "initial" }}
      name="date"
      minDate={moment().toDate()}
      label="Дата поездки"
    />
  );
};

const defaultCityValues = { id: 1, id2: 97 };

const SearchForm = ({
  isRoutes,
  passengerOff,
  onSearch,
  btnText = "Найти билет"
}: {
  isRoutes?: boolean;
  passengerOff?: boolean;
  onSearch: (search: any) => Promise<void>;
  btnText?: string;
}) => {
  const [defaultOptions, setDefaultOptions] = useState<City[]>([]);

  const [initialValue, setInitialValue] = useState<InitialValues>({
    type: "1",
    from: null,
    to: null,
    date: moment().format("YYYY-MM-DD"),
    dateBack: moment().add(1, "day").format("YYYY-MM-DD"),
    adult: 1,
    childs: 0
  });

  const cities = useAppSelector(selectCities);
  const router = useRouter();
  const saveDate = (dateT: string) => {
    const formattedDate = moment(dateT, ["YYYY-MM-DD", "YYYY-M-DD"]);
    const isValid = formattedDate.isValid();

    if (isValid) {
      return formattedDate.format("YYYY-MM-DD");
    } else {
      return moment().format("YYYY-MM-DD");
    }
  };

  useEffect(() => {
    const { routes, date, dateBack, adult, childs } = router.query;
    const [cityOne, cityTwo] = routes ? parseSearchString(routes[0]) : [];

    const extractCityFromOptions = (cityName: string) =>
      cities.find((city) => city.latin_name === cityName) ||
      defaultOptions.find((option) => option.id === defaultCityValues.id);

    const fromCity = cityOne ? extractCityFromOptions(cityOne) : null;
    const toCity = cityTwo ? extractCityFromOptions(cityTwo) : null;

    setInitialValue((prev) => {
      const initDate = date || prev.date;
      const initDateBack = dateBack || prev.dateBack;
      let type = "1";

      if (dateBack?.length) {
        type = "2";
      }

      return {
        ...prev,
        from: fromCity || defaultOptions.find((option) => option.id === defaultCityValues.id),
        to: toCity || defaultOptions.find((option) => option.id === defaultCityValues.id2),
        date: saveDate(initDate),
        dateBack: saveDate(initDateBack),
        adult: Number(adult) || 1,
        childs: Number(childs) || 0,
        type
      };
    });
  }, [router.query, cities, defaultOptions]);

  const searchTracks = async (value: InitialValues) => {
    if (!IS_DEVELOP && !isRoutes) {
      ym("reachGoal", "find-ticket");
    }
    const formattedDate = moment(value.date).format("YYYY-M-DD");
    await onSearch({
      from: value.from,
      to: value.to,
      date: formattedDate,
      adult: value.adult,
      childs: value.childs,
      dateBack: value.type === "1" ? null : value.dateBack
    });
  };

  const options = [
    { label: "В одну сторону", value: "1" },
    { label: "Туда-обратно", value: "2" }
  ];

  const loadOptions = async (query: string): Promise<void> => {
    const searchString = query ? `?search=${query}` : "";
    const { data } = await httpClient.get(`city${searchString}`);
    return data;
  };
  const debouncedLoadOptions = debounceAsync(loadOptions, 300);

  useEffect(() => {
    httpClient.get("city").then(({ data }) => setDefaultOptions(data));
  }, []);

  return (
    <>
      {!isRoutes && (
        <S.FlexContainer>
          <S.FlexLink active href="/">
            <Image src={bus} alt="Автобусы" width={32} height={32} />
            <S.Text>Автобусы</S.Text>
          </S.FlexLink>
          <S.FlexLink href="https://relax.probilets.com" rel="nofollow">
            <Image src={relax} alt="Отдых" width={32} height={32} />
            <S.Text>Отдых</S.Text>
          </S.FlexLink>
          <S.FlexLink href="https://hotels.probilets.com" rel="nofollow">
            <Image src={hotel} alt="Отель" width={32} height={32} />
            <S.Text>Отели</S.Text>
          </S.FlexLink>
          <S.FlexLink href="https://procars.pro" rel="nofollow">
            <Image src={car} alt="Procars" width={32} height={32} />
            <S.Text>Procars</S.Text>
          </S.FlexLink>
        </S.FlexContainer>
      )}
      <Formik initialValues={initialValue} enableReinitialize onSubmit={searchTracks}>
        {({ isSubmitting }) => (
          <Form>
            <S.SearchFormWrapper isRoutes={isRoutes}>
              <S.SearchRow>
                <RadioField name="type" options={options} defaultValue="1" />
              </S.SearchRow>
              <S.SearchRow>
                <S.SearchCityWrapper>
                  <SelectField
                    label="Откуда"
                    name="from"
                    async
                    defaultOptions={defaultOptions}
                    getOptionLabel={(option: City) => option.name}
                    getOptionValue={(option: City) => option.latin_name}
                    loadOptions={debouncedLoadOptions}
                  />
                  <RenderSwapButton />
                  <SelectField
                    label="Куда"
                    name="to"
                    async
                    defaultOptions={defaultOptions}
                    getOptionLabel={(option: City) => option.name}
                    getOptionValue={(option: City) => option.latin_name}
                    loadOptions={debouncedLoadOptions}
                  />
                </S.SearchCityWrapper>
                <RenderDate />
                <Passangers disable={!passengerOff} />
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <NinetyRingWithBg color="#fff" /> : btnText}
                </Button>
              </S.SearchRow>
            </S.SearchFormWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default memo(SearchForm);
